import styled from "@emotion/styled";
import { ImgLoadingDispatchContext } from "internal/context";
import { bannerList } from "internal/siteInfos";
import { useContext, useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { useMediaQuery } from "react-responsive";

const HomeWrapper = styled.div`
    height: 100%;
    margin-bottom: 30px;
`;

const BannerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BannerImg = styled.img`
    width: 88%;
`;

function Home() {
    const imgLoadingDispatch = useContext(ImgLoadingDispatchContext);
    const isPhone = useMediaQuery({ maxWidth: "1200px" });

    return (
        <HomeWrapper className="home-page">
            <Carousel>
                {
                    bannerList.map((banner, index) => {
                        return (
                            <BannerWrapper key={index}>
                                <BannerImg
                                    onChange={() => console.log(123)}
                                    onLoad={() => imgLoadingDispatch({ type: "DELETE", imgName: banner })}
                                    onError={() => imgLoadingDispatch({ type: "DELETE", imgName: banner })}
                                    src={`${process.env.PUBLIC_URL}/banners/${isPhone ? "small/" : ""}${banner}`}
                                    alt=""
                                />
                            </BannerWrapper>
                        );
                    })
                }
            </Carousel>
        </HomeWrapper>
    );
}

export default Home;