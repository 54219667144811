import About from "pages/About";
import Home from "pages/Home";
import News from "pages/News";
import Team from "pages/Team";

export const navbarItems = [
    {
        name: "home",
        disabled: false,
        pageComponent: Home
    },
    {
        name: "news",
        disabled: false,
        pageComponent: News
    },
    {
        name: "team",
        disabled: false,
        pageComponent: Team
    },
    {
        name: "store",
        disabled: true
    },
    {
        name: "about",
        disabled: false,
        pageComponent: About
    }
];

export const partnerLogos = [
    "윤호21병원.svg",
    "인성메디칼.svg"
]

export const gamerList = [
    {
        gameName: "r6",
        gameIcon: "r6.svg",
        gamers: [
            {
                name: "aEnde",
                twitterLink: "https://twitter.com/aEnde_",
                youtubeLink: "https://www.youtube.com/channel/UCII6rRIeyMhwKYsTXMo6lYg"
            },
            {
                name: "Baguette",
                twitterLink: "https://twitter.com/BaguetteR6_",
                youtubeLink: "https://www.youtube.com/@baguetteR6"
            },
            {
                name: "EroSA",
                twitterLink: "https://twitter.com/EGGR6S_",
                youtubeLink: ""
            },
            {
                name: "Guard",
                twitterLink: "https://twitter.com/Guard_R6",
                youtubeLink: ""
            },
            {
                name: "Honey",
                twitterLink: "https://twitter.com/HoneyngGG",
                youtubeLink: ""
            },
            {
                name: "LeeDaeMin",
                twitterLink: "https://twitter.com/PeaceMa31434858",
                youtubeLink: ""
            }
        ]
    },
    {
        gameName: "val",
        gameIcon: "val.svg",
        gamers: [
            {
                name: "K1rby",
                twitterLink: "https://twitter.com/K1rbyvlrt",
                youtubeLink: ""
            },
            {
                name: "332",
                twitterLink: "https://twitter.com/332vlr",
                youtubeLink: ""
            },
            {
                name: "BIBI",
                twitterLink: "https://twitter.com/BiBiVlrt",
                youtubeLink: ""
            },
            {
                name: "dorothy",
                twitterLink: "",
                youtubeLink: ""
            },
            {
                name: "h4ste",
                twitterLink: "https://twitter.com/H4steVlrt",
                youtubeLink: ""
            },
            {
                name: "jjuuBi",
                twitterLink: "https://twitter.com/jjuuBi_0",
                youtubeLink: ""
            }
        ]
    }
]

export const bannerList = [
    "welcome-official.webp",
    "welcome-academy.webp",
    "jersey-giveaway.webp",
    "welcom-vlrt-gc.webp"
]