import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Stack, Grid } from "@mui/material";
import YouTube from "react-youtube";

const NewsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #173994;
    margin-bottom: 30px;
`;

const TwitterTimeline = styled.a`
    width: 80%;
    height: 500px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
`;

const EmptyVideo = styled.div`
    width: 100%;
    height: 250px;
    background-color: #EEEEEE;
    border-radius: 8px;
`;

function News() {
    const [videos, setVideos] = useState([]);
    
    const videoOption = {
        height: "250px",
        width: "100%",
        playerVars: {
            autoplay: 0,
        },
    };

    function renderEmptyVideo() {
        const result = [];

        for (let i = 0; i < 6 - videos.length; i++) {
            result.push(
                <Grid item key={i} xs={7} sm={4} md={3}>
                    <EmptyVideo />
                </Grid>
            );
        }

        return result;
    }

    useEffect(() => {
        async function fetchVideos() {
            let videoData = [];
        
            try {
                const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
                const channelId = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;
                const response = await fetch(
                    `https://youtube.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&maxResults=6`
                );
        
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
        
                const data = await response.json();

                if (data.items) {
                    videoData = [...data.items.filter(element => element.id.kind === "youtube#video")];
                }
            } catch (error) {
                console.error(error);
            }
        
            return videoData;
        }

        const body = document.querySelector("body");

        const scriptElement = document.createElement("script");
        scriptElement.src = "https://platform.twitter.com/widgets.js";
        scriptElement.charSet = "utf-8";

        body.appendChild(scriptElement);

        fetchVideos().then((result) => {
            setVideos(result);
        });
    }, []);

    return (
        <NewsWrapper className="news-page">
            <h1>NEWS</h1>
            <Stack alignItems="center" width="100%" gap={3}>
                <TwitterTimeline
                    className="twitter-timeline"
                    data-height="500"
                    href="https://twitter.com/MirGG_kr?ref_src=twsrc%5Etfw"
                    data-chrome=" transparent nofooter noheader noscrollbar"
                ></TwitterTimeline>
                <Grid container gap={2} justifyContent="center">
                    {
                        videos.length > 0 && videos.map((video, index) => {
                            return(
                                <Grid item key={index} xs={7} sm={4} md={3} style={{ borderRadius: "8px", overflowX: "hidden", padding: "0", margin: "0", boxSizing: "border-box" }}>
                                    <YouTube opts={videoOption} videoId={video.id.videoId} />
                                </Grid>
                            );
                        })
                    }
                    {videos.length > 0 && renderEmptyVideo()}
                </Grid>
            </Stack>
        </NewsWrapper>
    );
}

export default News;