import styled from "@emotion/styled";
import { Button, Grid, ThemeProvider, createTheme } from "@mui/material";
import { partnerLogos } from "internal/siteInfos";
import EmailIcon from '@mui/icons-material/Email';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useContext } from "react";
import { ImgLoadingDispatchContext } from "internal/context";

const AboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #173994;
    margin-bottom: 30px;
`;

const PartnerLogo = styled.img`
    width: 200px;
    height: 100px;
    object-fit: cover;
`;

const contactUsTheme = createTheme({
    palette: {
        contactButton: {
            main: "#EEEEEE",
            contrastText: "#3b81c4"
        }
    }
});

function About() {
    const imgLoadingDispatch = useContext(ImgLoadingDispatchContext);

    return (
        <AboutWrapper className="about-page">
            <h1>OUR PARTNERS</h1>
            <Grid style={{ marginBottom: "100px" }} container justifyContent="center" alignItems="center">
                {
                    partnerLogos.map((logoFileName, index) => {
                        return (
                            <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={7} sm={5} md={4} lg={3} key={index}>
                                <PartnerLogo
                                    onLoad={() => imgLoadingDispatch({ type: "DELETE", imgName: logoFileName })}
                                    onError={() => imgLoadingDispatch({ type: "DELETE", imgName: logoFileName })}
                                    key={index}
                                    src={`${process.env.PUBLIC_URL}/logos/${logoFileName}`}
                                    alt="" />
                            </Grid>
                        );
                    })
                }
            </Grid>
            <h1>CONTACT US</h1>
            <ThemeProvider theme={contactUsTheme}>
                <Grid container style={{ width: "80%" }} justifyContent="center" gap={3}>
                    <Grid style={{ display: "flex", justifyContent: "center" }} item>
                        <Button
                            color="contactButton"
                            variant="contained"
                            startIcon={<EmailIcon />}
                            onClick={() => window.open("mailto:support@mirgg.kr")}
                        >
                            support@mirgg.kr
                        </Button>
                    </Grid>
                    <Grid style={{ display: "flex", justifyContent: "center" }} item>
                        <Button
                            color="contactButton"
                            variant="contained"
                            startIcon={<XIcon />}
                            onClick={() => window.open("https://twitter.com/MirGG_kr")}
                        >
                            MirGG_kr
                        </Button>
                    </Grid>
                    <Grid style={{ display: "flex", justifyContent: "center" }} item>
                        <Button
                            color="contactButton"
                            variant="contained"
                            startIcon={<InstagramIcon />}
                            onClick={() => window.open("https://www.instagram.com/mirgg_kr/")}
                        >
                            MirGG_kr
                        </Button>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </AboutWrapper>
    );
}

export default About;