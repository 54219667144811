import styled from "@emotion/styled";
import { Box, Grid, IconButton, Stack, Tab, Tabs } from "@mui/material";
import { gamerList } from "internal/siteInfos";
import { useContext, useState } from "react";

import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { ImgLoadingDispatchContext } from "internal/context";

const ProfileWrapper = styled.div`
    display: flex;
    justify-content: center;
    border: 2px solid #EEEEEE;
    border-radius: 8px;

    min-width: 150px;
    height: auto;
`;
const ProfileImg = styled.img`
    width: 100px;
    height: auto;
    border-radius: 8px;
`;
const ProfileName = styled.h2`
    text-align: center;
    padding: 0px 5px;
    margin: 10px 0px;
`;

function Profile({ gamer, gameName }) {
    const imgLoadingDispatch = useContext(ImgLoadingDispatchContext);

    return (
        <ProfileWrapper>
            <Stack alignItems="center">
                <ProfileImg
                    onLoad={() => imgLoadingDispatch({ type: "DELETE", imgName: `${gamer.name}.webp` })}
                    onError={(event) => {
                        console.log(123123);
                        event.target.src = `${process.env.PUBLIC_URL}/default-user-image.png`;
                        event.target.style.marginTop = "20px";
                        imgLoadingDispatch({ type: "DELETE", imgName: `${gamer.name}.webp` })
                    }}
                    src={`${process.env.PUBLIC_URL}/gamers/${gameName}/${gamer.name}.webp`}
                    alt=""
                />
                <ProfileName>{gamer.name}</ProfileName>
                <Stack direction="row" style={{ marginBottom: "10px", height: "40px" }}>
                    {
                        (gamer.twitterLink && gamer.twitterLink !== "") &&
                        <IconButton onClick={() => window.open(gamer.twitterLink)}>
                            <XIcon />
                        </IconButton>
                    }
                    {
                        (gamer.youtubeLink && gamer.youtubeLink !== "") &&
                        <IconButton onClick={() => window.open(gamer.youtubeLink)}>
                            <YouTubeIcon />
                        </IconButton>
                    }
                </Stack>
            </Stack>
        </ProfileWrapper>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ width: "95%" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, pt: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function TeamTab() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex", width: "80%" }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{ borderRight: 1, borderColor: "divider" }}
            >
                {
                    gamerList.map((tabInfo, index) => {
                        return (
                            <Tab key={index} icon={<img height="30px" src={`${process.env.PUBLIC_URL}/logos/${tabInfo.gameIcon}`} alt="" />} {...a11yProps(index)} />
                        );
                    })
                }
            </Tabs>
            {
                gamerList.map((tabInfo, index) => {
                    return (
                        <TabPanel key={index} value={value} index={index}>
                            <Grid container justifyContent="center" alignItems="center" gap={5}>
                                {
                                    tabInfo.gamers.map((gamer, index) => {
                                        return (
                                            <Grid style={{ maxWidth: "200px", display: "flex", justifyContent: "center" }} item key={index}>
                                                <Profile gamer={gamer} gameName={tabInfo.gameName} />
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </TabPanel>
                    );
                })
            }
        </Box>
    );
}

export default TeamTab;