import styled from "@emotion/styled";
import TeamTab from "component/TeamTab";

const TeamWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #173994;
    margin-bottom: 50px;
`;

function Team() {
    return (
        <TeamWrapper className="team-page">
            <h1>TEAM</h1>
            <TeamTab />
        </TeamWrapper>
    );
}

export default Team;