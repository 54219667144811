import { createContext, useEffect, useReducer } from "react";
import { bannerList, gamerList, partnerLogos } from "./siteInfos";

export const initalPage = "home";
export const PageContext = createContext();
export const PageDispatchContext = createContext();
function pageReducer(state, action) {
    switch (action.type) {
        case "SET":
            return action.selectedPage;
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

export const initialImgLoading = [];
export const ImgLoadingContext = createContext();
export const ImgLoadingDispatchContext = createContext();
function imgLoadingReducer(state, action) {
    switch (action.type) {
        case "SET":
            return action.imgLoadingList;
        case "ADD":
            return [...state, action.imgName];
        case "DELETE":
            return state.filter(imgName => imgName !== action.imgName);
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

export function GlobalContextProvider({ children }) {
    const [page, pageDispatch] = useReducer(pageReducer, initalPage);
    const [imgLoading, imgLoadingDispatch] = useReducer(imgLoadingReducer, initialImgLoading);

    useEffect(() => {
        const imgLoadingList = [...bannerList, ...partnerLogos];
        const body = document.querySelector("body");

        body.classList.add("stop-scrolling");

        if (gamerList.length > 0) {
            gamerList[0].gamers.map((gamer) => (
                imgLoadingList.push(`${gamer.name}.webp`)
            ))
        }

        imgLoadingDispatch({ type: "SET", imgLoadingList });
    }, []);

    return (
        <PageContext.Provider value={page}>
            <PageDispatchContext.Provider value={pageDispatch}>
                <ImgLoadingContext.Provider value={imgLoading}>
                    <ImgLoadingDispatchContext.Provider value={imgLoadingDispatch}>
                        {children}
                    </ImgLoadingDispatchContext.Provider>
                </ImgLoadingContext.Provider>
            </PageDispatchContext.Provider>
        </PageContext.Provider>
    );
}