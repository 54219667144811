import styled from "@emotion/styled";
import Loader from "react-js-loader";
import { ImgLoadingContext } from "internal/context";
import { useContext, useEffect, useState } from "react";

const LoadingWrapper = styled.div`
    width: 100vw;
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecf0f1;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
`;

function Loading() {
    const loadingImgs = useContext(ImgLoadingContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const body = document.querySelector("body");
        
        if (loadingImgs.length <= 0) {
            body.classList.remove("stop-scrolling");
            setIsLoading(false);
        } else {
            body.classList.add("stop-scrolling");
            setIsLoading(true);
        }
    }, [loadingImgs]);

    if (isLoading) {
        return (
            <LoadingWrapper>
                <Loader type="box-rectangular" color="#173994" bgColor="#173994" title="이미지 로딩중..." />
            </LoadingWrapper>
        );
    }
}

export default Loading;