import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { navbarItems } from "internal/siteInfos";
import { useContext, useState } from "react";
import { Pivot as Hamburger } from 'hamburger-react'
import { motion } from "framer-motion"
import { PageDispatchContext } from "internal/context";
import { useMediaQuery } from "react-responsive";

const NavbarWrapper = styled.div`
    position: sticky;
    top: 0px;
    
    width: calc(100vw - 40px);
    max-height: 45px;
    padding: 20px;
    background-color: white;
    border-bottom: 1px solid #EEEEEE;

    z-index: 999;
`;

const NavbarIcon = styled.img`
    width: 106px;
    height: 30px;
    margin-bottom: 5px;
`;

const NavbarText = styled.span`
    text-align: center;
    padding: 0 50px;

    font-size: 17px;
    font-weight: 500;
    color: #444444;
    cursor: pointer;
    margin-bottom: 5px;

    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    &:hover {
        color: #173994;
    }
`;

const menuVariants = {
    open: { height: "100%" },
    closed: { height: "0px" }
}

function NavBarContent() {
    const pageDispatch = useContext(PageDispatchContext);
    const result = [];

    navbarItems.map((navbarItem, index) => (
        result.push(
            <NavbarText key={index} onClick={() => { pageDispatch({ type: "SET", selectedPage: navbarItem.name.toLowerCase() }); }}>
                {navbarItem.name.toUpperCase()}
            </NavbarText>
        )
    ));
    return result;
}

function Navbar() {
    const isPhone = useMediaQuery({ maxWidth: "1200px" });
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    return (
        <>
            <NavbarWrapper>
                <Stack alignItems="center" direction="row" width="100%" height="100%">
                    <Stack alignItems="center">
                        <NavbarIcon src={`${process.env.PUBLIC_URL}/logos/mir-logo.png`} alt="" />
                    </Stack>
                    {
                        !isPhone ?
                            <Stack alignItems="center" style={{ justifyContent: "center", height: "48px" }} direction="row" width="100%">
                                {NavBarContent()}
                            </Stack>
                            :
                            <Stack style={{ justifyContent: "end" }} direction="row" width="100%">
                                <Hamburger color="#999999" toggled={menuIsOpen} toggle={setMenuIsOpen} />
                            </Stack>
                    }
                </Stack>
            </NavbarWrapper>
            {
                isPhone &&
                <motion.div
                    style={{
                        backgroundColor: "white",
                        width: "100vw",
                        height: "0px",
                        overflow: "hidden",
                        borderTop: "1px solid #EEEEEE",
                        borderBottom: "1px solid #EEEEEE",
                        position: "sticky",
                        top: "85px",
                        zIndex: "999"
                    }}
                    animate={menuIsOpen ? "open" : "closed"}
                    variants={menuVariants}
                    transition={{ duration: 0.3 }}
                >
                    <Stack alignItems="center" style={{ padding: "30px 0" }} gap={3} width="100%">
                        {NavBarContent()}
                    </Stack>
                </motion.div>
            }
        </>
    );
}

export default Navbar;