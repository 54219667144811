import styled from "@emotion/styled";

const FooterWrapper = styled.div`
    height: 100px;
    padding: 30px;
    padding-top: 0px;
    border-top: 1px solid #EEEEEE;
`;

const FooterTitle = styled.h4`
    margin: 20px 0px 5px 0px;
    color: rgb(209 213 219/var(1));
    font-weight: 1000;
    color: #616161;
`;

const FooterContent = styled.h5`
    margin: 0;
    font-weight: 500;
    color: #757575;
`;

function Footer() {
    return(
        <FooterWrapper>
            <FooterTitle>유한회사 미르</FooterTitle>
            <FooterContent>대표: 김정희</FooterContent>
            <FooterContent>주소: 전남 고흥군 고흥읍 터미널길 16</FooterContent>
            <FooterContent>사업자등록번호: 781-87-02995</FooterContent>
            <FooterContent>스폰서십 문의메일: kayo.m9a1@gmail.com</FooterContent>
        </FooterWrapper>
    );
}

export default Footer;