import Footer from "component/Footer";
import Navbar from "component/Navbar";
import { PageContext, PageDispatchContext } from "internal/context";
import { navbarItems } from "internal/siteInfos";
import Loading from "pages/Loading";
import { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

function App() {
  const nowPage = useContext(PageContext);
  const pageDispatch = useContext(PageDispatchContext);
  const isPhone = useMediaQuery({ maxWidth: "1200px" });

  window.addEventListener("scroll", () => {
    if (nowPage !== "") {
      pageDispatch({ type: "SET", selectedPage: "" });
    }
  });

  useEffect(() => {
    const navbarItem = navbarItems.filter(item => item.name.toLowerCase() === nowPage)[0];
    if (navbarItem?.disabled) {
      alert("준비중입니다.");
      return;
    }

    const proceedPageName = nowPage.replace(" ", "-");
    const pageElement = document.querySelector(`.${proceedPageName}-page`);

    if (!pageElement) {
      return;
    }

    const pagePosition = pageElement.getBoundingClientRect().top;
    const headerOffset = isPhone ? 375 : 80;
    const offsetPosition = pagePosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }, [nowPage]);

  return (
    <div className="App">
      <Loading />
      <Navbar />
      {
        navbarItems.map((item, index) => {
          if (item.pageComponent) {
            return (
              <item.pageComponent key={index} />
            );
          }
        })
      }
      <Footer />
    </div>
  );
}

export default App;
